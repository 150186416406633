import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { nodePropType } from 'shared/propTypes';
import './button.scss';

const Button = ({
  btnType = 'button',
  handleClick,
  type,
  children,
  disabled,
  id,
  linkAsButton,
  path,
}) =>
  !linkAsButton ? (
    <button
      type={btnType}
      id={id}
      className={`${type ? `button ${type}` : 'button'}`}
      onClick={e => _.isFunction(handleClick) && handleClick(e)}
      disabled={disabled}
    >
      {children}
    </button>
  ) : (
    <Link
      className={`${type ? `button ${type}` : 'button'} linkAsButton`}
      to={path}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </Link>
  );

Button.propTypes = {
  children: nodePropType.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

Button.defaultProps = {
  id: '',
};

export default Button;
